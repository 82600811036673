import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';

import { GIFTS_COLLECTION_NAME, USERS_COLLECTION_NAME } from '../constants';
import GiftDetailsCard from '../global/GiftDetailsCard';

export const DetailsScene = () => {
  let { id: giftId } = useParams();

  const auth = useSelector(({ firebase: { auth } }) => auth);
  const gift = useSelector(
    ({ firestore: { data } }) =>
      data[GIFTS_COLLECTION_NAME] && data[GIFTS_COLLECTION_NAME][giftId]
  );
  const users = useSelector(
    ({ firestore: { data } }) => data[USERS_COLLECTION_NAME]
  );
  useFirestoreConnect(() => [
    {
      collection: GIFTS_COLLECTION_NAME,
      doc: giftId
    },
    {
      collection: USERS_COLLECTION_NAME
    }
  ]);

  const giftAuthor = useMemo(
    () => gift && users && users[gift.authorId],
    [gift, users]
  );
  const giftRecipient = useMemo(() => {
    return gift && users && users[gift.recipientId];
  }, [gift, users]);
  const userIsAuthor = giftAuthor && auth.uid === giftAuthor.uid;
  const userIsRecipient = giftRecipient && auth.uid === giftRecipient.uid;

  if (!gift || !users) return null;

  return (
    <GiftDetailsCard
      gift={gift}
      giftId={giftId}
      userIsAuthor={userIsAuthor}
      giftAuthor={giftAuthor}
      userIsRecipient={userIsRecipient}
      auth={auth}
    />
  );
};

export default DetailsScene;
