import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useFirestore, useFirestoreConnect } from 'react-redux-firebase';
import { Header, Card, Button } from 'semantic-ui-react';

import { Gift as GiftType } from '../../../prop-types';
import { GIFTS_COLLECTION_NAME } from '../../../constants';

import strings from '../../../strings';

const GiftDetailCardButtons = ({ gift, giftId, auth }) => {
  const firestore = useFirestore();
  let history = useHistory();

  const callbackEditGift = useCallback(() => {
    history.push(`/add/${giftId}`);
  }, []);

  const callbackToggleGift = useCallback(() => {
    firestore.update(
      { collection: GIFTS_COLLECTION_NAME, doc: giftId },
      {
        isClaimed: gift.isClaimed ? false : true,
        claimerId: gift.isClaimed ? null : auth.uid
      }
    );
  }, [gift]);

  const callbackTogglePurchased = useCallback(() => {
    firestore.update(
      { collection: GIFTS_COLLECTION_NAME, doc: giftId },
      {
        isPurchased: gift.isPurchased ? false : true
      }
    );
  }, [gift]);

  const callbackDeleteGift = () => {
    console.log('alexalex - !!!!!!!! Details.js:29', 'TODO');
  };

  let buttons = [];
  if (auth.uid === gift.authorId) {
    buttons.push(
      <Button
        key="edit"
        aria-label="edit"
        basic
        color="green"
        onClick={callbackEditGift}
      >
        {strings.detailsEditButton}
      </Button>
      // <Button
      //   key="delete"
      //   aria-label="delete"
      //   basic
      //   color="red"
      //   onClick={callbackDeleteGift}
      // >
      //   {strings.detailsDeleteButton}
      // </Button>
    );
  }

  if (auth.uid !== gift.recipientId) {
    if (gift.isClaimed) {
      if (auth.uid === gift.claimerId) {
        buttons.push(
          <Button
            key="toggle-claim"
            aria-label="claim"
            color="violet"
            onClick={callbackToggleGift}
          >
            {strings.detailsYouAlreadyClaimedThisButton}
          </Button>
        );
      } else {
        buttons.push(
          <Button
            key="toggle-claim"
            aria-label="claim"
            color="violet"
            onClick={callbackToggleGift}
          >
            {strings.detailsAlreadyClaimedButton}
          </Button>
        );
      }

      if (auth.uid === gift.claimerId) {
        if (gift.isPurchased) {
          buttons.push(
            <Button
              key="toggle-purchase"
              aria-label="purchase"
              color="teal"
              onClick={callbackTogglePurchased}
            >
              {strings.detailsYouAlreadyPurchasedThisButton}
            </Button>
          );
        } else {
          buttons.push(
            <Button
              key="toggle-purchase"
              aria-label="purchase"
              basic
              color="teal"
              onClick={callbackTogglePurchased}
            >
              {strings.detailsNotYetPurchasedThisButton}
            </Button>
          );
        }
      }
    } else {
      buttons.push(
        <Button
          key="toggle-claim"
          aria-label="claim"
          basic
          color="violet"
          onClick={callbackToggleGift}
        >
          {strings.detailsNotYetClaimedButton}
        </Button>
      );
    }
  }

  return buttons && <div className="ui three buttons">{buttons}</div>;
};

GiftDetailCardButtons.propTypes = {
  giftId: PropTypes.string.isRequired,
  gift: GiftType
};

export default GiftDetailCardButtons;
