import React from 'react';
import { List, Header, Image, Divider } from 'semantic-ui-react';

import GiftNext from '../GiftNext';

const RecipientWithGifts = ({
  user: { photoURL, uid, displayName } = {},
  gifts = [],
  showUserUidInList,
  userIsRecipient,
  showHeader = true,
  labelOptions,
  showDivider = true
}) => {
  return (
    <>
      {showHeader && (
        <Header as="h3">
          <Image circular src={photoURL} /> {displayName}{' '}
        </Header>
      )}
      <List relaxed>
        {gifts.length === 0 && (
          <List.Item>
            <Header size="medium">No gifts yet</Header>
          </List.Item>
        )}
        {gifts.map((g) => (
          <GiftNext
            key={g.id}
            gift={g}
            labelOptions={labelOptions}
            userIsRecipient={userIsRecipient}
          />
        ))}
      </List>
      {showDivider && <Divider />}
    </>
  );
};

export default RecipientWithGifts;
