import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { compose } from 'react-recompose';
import {
  firestoreConnect,
  useFirestore,
  useFirestoreConnect
} from 'react-redux-firebase';
import _orderBy from 'lodash/orderBy';
import {
  Form,
  Button,
  Dropdown,
  Segment,
  Header,
  TextArea,
  Checkbox,
  Icon
} from 'semantic-ui-react';
import { toast } from 'react-semantic-toasts';

import { GIFTS_COLLECTION_NAME, USERS_COLLECTION_NAME } from '../constants';
import strings from '../strings';

const segmentStyles = {
  flexGrow: 1,
  marginBottom: '1rem',
  marginTop: 0
};

const errorToastPropsMissingGift = {
  type: 'error',
  icon: 'x',
  title: 'Your Gift Was NOT Added',
  description: `Please enter a name.`,
  time: 3000
};

const errorToastPropsMissingRecipient = {
  type: 'error',
  icon: 'x',
  title: 'Your Gift Was NOT Added',
  description: `Please enter a recipient.`,
  time: 3000
};

const errorToastPropsUnclear = {
  type: 'error',
  icon: 'x',
  title: 'Your Gift Was NOT Added or Editted',
  description: `Please try again later.`,
  time: 3000
};

export const AddForm = ({ authUid, users = {}, gift = {}, isEdit, giftId }) => {
  const firestore = useFirestore();
  const [isSubmitting, setIsSubmitting] = useState(false);
  console.log('alexalex - !!!!!!!! Add.js:36', { gift });

  const defaultFormValue = (gift) => {
    console.log('alexalex - ******** Add.js:39', gift);
    return {
      name: '',
      link: '',
      isSuggestion: false,
      isClaimed: false,
      claimerId: '',
      recipientId: authUid,
      description: '',
      ...gift
    };
  };

  const [value, setValue] = useState(defaultFormValue(gift));

  let dropdownValues = useMemo(() => {
    if (!value.isSuggestion) return [];

    return Object.entries(users)
      .filter(([_, value]) => !!value.displayName) // remove users without a display name
      .filter(([id]) => id !== authUid) // remove the current user
      .map(([key, value]) => ({
        key,
        value: key,
        text: value.displayName,
        image: { avatar: true, src: value.photoURL }
      }));
  }, [users, value.isSuggestion]);

  const updateRecipient = (e) => {
    const isSuggestion = e.target.value === 'true';
    if (isSuggestion) {
      setValue({
        ...value,
        recipientId: '',
        isSuggestion,
        isClaimed: false,
        claimerId: null
      });
    } else {
      setValue({
        ...value,
        recipientId: authUid,
        isSuggestion,
        isClaimed: false,
        claimerId: null
      });
    }
  };

  const updateField = (e) => {
    const currValue = e.target.value;
    setValue({
      ...value,
      [e.target.name]: currValue
    });
  };

  const claimerField = (e, { checked }) => {
    if (checked) {
      setValue({
        ...value,
        claimerId: authUid,
        isClaimed: true
      });
    } else {
      setValue({
        ...value,
        claimerId: null,
        isClaimed: false
      });
    }
  };

  const updateDropdown = (e, dropdown) => {
    setValue({
      ...value,
      recipientId: dropdown.value
    });
  };

  const clickHandler = (e) => {
    e.preventDefault();
    if (!value.name) {
      toast(errorToastPropsMissingGift);
      return;
    }
    if (value.isSuggestion && !value.recipientId) {
      toast(errorToastPropsMissingRecipient);
      return;
    }

    const objToAdd = {
      name: value.name,
      link: value.link || '',
      recipientId: value.recipientId,
      // isClaimed: false,
      isSuggestion: value.isSuggestion,
      description: value.description,
      createdAt: Date.now(),
      authorId: authUid,
      isClaimed: value.isClaimed,
      claimerId: value.claimerId
    };

    setIsSubmitting(true);

    if (isEdit && gift) {
      console.log('alexalex - !!!!!!!! Add.js:173', giftId);
      firestore
        .collection(GIFTS_COLLECTION_NAME)
        .doc(giftId)
        .update(objToAdd)
        .then((doc) => {
          toast({
            type: 'success',
            icon: 'gift',
            title: 'Your Gift Was Editted',
            description: `You have successfully editted "${objToAdd.name}".`,
            time: 3000
          });
          setIsSubmitting(false);
        })
        .catch((e) => {
          toast(errorToastPropsUnclear);
          setIsSubmitting(false);
        });
    } else {
      firestore
        .collection(GIFTS_COLLECTION_NAME)
        .add(objToAdd)
        .then((doc) => {
          toast({
            type: 'success',
            icon: 'gift',
            title: 'Your Gift Was Added',
            description: `You have successfully added "${objToAdd.name}" to the list.`,
            time: 3000
          });
          setIsSubmitting(false);
          setValue(defaultFormValue());
        })
        .catch((e) => {
          toast(errorToastPropsUnclear);
          setIsSubmitting(false);
        });
    }
  };

  return (
    <Segment inverted style={segmentStyles}>
      <Header as="h1">{strings._addFormHeader}</Header>
      <Form inverted>
        <Form.Field>
          <label>{strings._addFormIsSuggestionLabel}</label>
        </Form.Field>
        <Form.Field
          label="Myself"
          control="input"
          type="radio"
          name="isSuggestion"
          value="false"
          checked={!value.isSuggestion}
          onChange={updateRecipient}
        />
        <Form.Field
          label="Someone else"
          control="input"
          type="radio"
          name="isSuggestion"
          value="true"
          checked={value.isSuggestion}
          onChange={updateRecipient}
        />
        <Form.Field>
          <Form.Input
            fluid
            label="Gift Name"
            name="name"
            onChange={updateField}
            value={value.name}
          />
        </Form.Field>
        <Form.Field>
          {value.isSuggestion && (
            <React.Fragment>
              <label>Recipient</label>
              <Dropdown
                onChange={updateDropdown}
                placeholder="Select a family member"
                fluid
                selection
                value={value.recipientId}
                disabled={dropdownValues.length === 0}
                options={dropdownValues}
              />
            </React.Fragment>
          )}
        </Form.Field>
        {/* {!value.isSuggestion && (
          <Form.Field>
            <label>How Much Do You Want This?</label>
            <Button.Group>
              <Button>very much</Button>
              <Button.Or />
              <Button>want</Button>
              <Button.Or />
              <Button>Save</Button>
            </Button.Group>
          </Form.Field>
        )} */}
        <Form.Field>
          <Form.Input
            fluid
            label="Link to specific item (optional)"
            placeholder="Link"
            name="link"
            onChange={updateField}
            value={value.link}
          />
        </Form.Field>
        <Form.Field>
          <label>Gift Description (optional)</label>
          <TextArea
            rows={3}
            placeholder="Description"
            name="description"
            onChange={updateField}
            value={value.description}
          />
        </Form.Field>
        {value.isSuggestion && (
          <Form.Field>
            <Checkbox
              label="Claim this item?"
              name="claim"
              onChange={claimerField}
              checked={value.isClaimed}
            />
          </Form.Field>
        )}
        <Button
          disabled={isSubmitting}
          onClick={clickHandler}
          icon
          labelPosition="right"
          type="submit"
          color="violet"
        >
          {strings.formatString(
            strings._addFormSubmitButtonText,
            giftId ? 'Edit' : 'Add'
          )}
          <Icon name="right gift" />
        </Button>
      </Form>
    </Segment>
  );
};

const AddScene = () => {
  let { id: giftId } = useParams();
  const auth = useSelector(({ firebase: { auth } }) => auth);
  const gift = useSelector(
    ({ firestore: { data } }) =>
      data[GIFTS_COLLECTION_NAME] && data[GIFTS_COLLECTION_NAME][giftId]
  );
  const users = useSelector(
    ({ firestore: { data } }) => data[USERS_COLLECTION_NAME]
  );
  const isEdit = !!gift;
  useFirestoreConnect(() => [
    {
      collection: GIFTS_COLLECTION_NAME,
      doc: giftId
    },
    {
      collection: USERS_COLLECTION_NAME
    }
  ]);

  if (giftId && !gift) {
    return 'loading';
  }

  return (
    <AddForm
      authUid={auth.uid}
      users={users}
      gift={gift}
      isEdit={isEdit}
      giftId={giftId}
    />
  );
};

export default AddScene;
