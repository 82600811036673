import React, { useMemo } from 'react';
import { connect, useSelector } from 'react-redux';
import { firestoreConnect, isLoaded, isEmpty } from 'react-redux-firebase';
import { compose } from 'redux';
import { Segment, Header, Divider } from 'semantic-ui-react';
import _orderBy from 'lodash.orderby';

import { GIFTS_COLLECTION_NAME, USERS_COLLECTION_NAME } from '../constants';

import RecipientWithGifts from '../global/RecipientWithGifts';

// TODO duplicate
const sortUserGifts = (gifts) => {
  return _orderBy(
    gifts,
    (i) => [!!i.isPurchased, !!i.isClaimed, !!i.isSuggestion],
    ['asc', 'asc', 'asc']
  );
};

const labelOptions = {
  // showPurchasedLabel: false,
  showSourceLabel: false
};

const FamilyScene = ({ users = {}, gifts = [], auth }) => {
  const showUserUidInList = useSelector(
    (state) => state.debug.showUserUidInList
  );
  const usersWithoutCurrent = Object.values(users).filter((currUser) => {
    return currUser.uid !== auth.uid;
  });

  const userGifts = useMemo(() => {
    return usersWithoutCurrent.reduce((acc, currUser) => {
      const filteredGifts = gifts.filter(
        (currGift) => currGift.recipientId === currUser.uid
      );

      if (!acc[currUser.uid]) {
        acc[currUser.uid] = sortUserGifts(filteredGifts);
      }
      return acc;
    }, {});
  });

  return !isLoaded(gifts, users) ? (
    'Loading Gifts' // TODO replace with loading component
  ) : isEmpty(gifts) ? (
    'Gift list is empty'
  ) : (
    <Segment className="family">
      <Header as="h1">Family List</Header>
      <>
        <p>- Your gifts are not listed here</p>
        <p>
          - The recipient will not know if an item has been purchased or claimed{' '}
        </p>
      </>
      <Divider />
      {usersWithoutCurrent.map((currUser, index) => {
        const showDivider = usersWithoutCurrent.length - 1 !== index;
        const userIsRecipient = auth.uid === currUser.uid;

        return (
          <RecipientWithGifts
            key={currUser.uid}
            gifts={userGifts[currUser.uid]}
            user={currUser}
            userIsRecipient={userIsRecipient}
            showUserUidInList={showUserUidInList}
            showDivider={showDivider}
            labelOptions={labelOptions}
          />
        );
      })}
    </Segment>
  );
};

export default compose(
  firestoreConnect([
    { collection: GIFTS_COLLECTION_NAME }, // TODO should filter out gifts where the recipient is the current user
    { collection: USERS_COLLECTION_NAME }
  ]),
  connect(({ firebase, firestore }) => {
    return {
      gifts: firestore.ordered[GIFTS_COLLECTION_NAME],
      users: firestore.data[USERS_COLLECTION_NAME],
      auth: firebase.auth,
      profile: firebase.profile
    };
  })
)(FamilyScene);
