import { applyMiddleware, createStore, compose } from 'redux';
import thunk from 'redux-thunk';
import { getFirebase } from 'react-redux-firebase';
import LogRocket from 'logrocket';

import rootReducer from './reducers';

export default function configureStore() {
  const middleware = [
    thunk.withExtraArgument({ getFirebase }),
    LogRocket.reduxMiddleware()
  ];
  const createStoreWithMiddleware = compose(
    applyMiddleware(...middleware),
    typeof window === 'object' &&
      typeof window.devToolsExtension !== 'undefined'
      ? window.__REDUX_DEVTOOLS_EXTENSION__ &&
          window.__REDUX_DEVTOOLS_EXTENSION__()
      : (f) => f
  )(createStore);
  const store = createStoreWithMiddleware(rootReducer);

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./reducers', () => {
      const nextRootReducer = require('./reducers');
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
}
