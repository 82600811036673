import React from 'react';
import { Header, Card } from 'semantic-ui-react';

import strings from '../../strings';

import LabelsRow from '../LabelsRow';
import GiftDetailCardButtons from './components/GiftDetailCardButtons';

const descStyles = {
  color: 'black',
  paddingTop: '30px'
};

const spanStyles = {
  opacity: 0.5,
  fontWeight: 100,
  textDecoration: 'underline'
};

const GiftDetailsCard = ({
  gift,
  giftId,
  userIsAuthor,
  giftAuthor,
  userIsRecipient,
  auth
}) => {
  const metaString = strings.formatString(
    strings.detailsGiftClaimStatus,
    userIsAuthor ? 'You' : giftAuthor.displayName,
    gift.isSuggestion ? 'suggested' : 'requested'
  );

  return (
    <Card fluid>
      <Card.Content>
        <Card.Header>
          <Header as="h2">
            <span style={spanStyles}>
              {gift.isSuggestion ? 'Sug.' : 'Req.'}
            </span>{' '}
            {gift.name}
          </Header>
        </Card.Header>
        <Card.Meta>{metaString}</Card.Meta>
        <Card.Description>
          <LabelsRow
            isClaimed={gift.isClaimed}
            isPurchased={gift.isPurchased}
            isSuggestion={gift.isSuggestion}
            showClaimedLabel={!userIsRecipient}
            showPurchasedLabel={!userIsRecipient}
          />
        </Card.Description>
        {gift.link && (
          <Card.Description>
            <a href={gift.link}>[LINK]</a>
          </Card.Description>
        )}
        {gift.description && (
          <Card.Description style={descStyles}>
            {gift.description}
          </Card.Description>
        )}
      </Card.Content>
      <Card.Content extra>
        <GiftDetailCardButtons gift={gift} auth={auth} giftId={giftId} />
      </Card.Content>
    </Card>
  );
};

export default GiftDetailsCard;
