import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  showUidInNav: false,
  showUserUidInList: false
};

const debugSlice = createSlice({
  name: 'debug',
  initialState,
  reducers: {
    changeValue(state, action) {
      const { id, value } = action.payload;
      state[id] = value;
    },
    toggleValue(state, action) {
      const { id } = action.payload;
      const currVal = state[id];
      state[id] = !currVal;
    }
  }
});

export const { changeValue, toggleValue } = debugSlice.actions;

export default debugSlice.reducer;
