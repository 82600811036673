import React, { useCallback } from 'react';
import { List, Header, Image } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';

import './styles.css';

import LabelsRow from '../LabelsRow';

const spanStyles = {
  opacity: 0.5,
  fontWeight: 100,
  textDecoration: 'underline'
};

const GiftNext = ({ gift, labelOptions = {}, userIsRecipient }) => {
  const history = useHistory();
  const { name, isClaimed, isSuggestion, isPurchased, link } = gift;

  const navigateToDetails = useCallback(() => {
    history.push(`/details/${gift.id}`);
  });

  const classes = classNames('list-item', {
    purchased: isPurchased,
    claimed: isClaimed,
    suggestion: isSuggestion,
    'is-recipient': userIsRecipient
  });

  return (
    <List.Item onClick={navigateToDetails} className={classes}>
      <List.Icon name={isSuggestion ? 'lightbulb' : 'pencil'} />
      <List.Content>
        <List.Header>
          <span style={spanStyles}>{isSuggestion ? 'Sug.' : 'Req.'}</span>{' '}
          {name}
        </List.Header>
        <List.Description>
          <LabelsRow
            condensed={labelOptions.condensed}
            showClaimedLabel={labelOptions.showClaimedLabel}
            showSourceLabel={labelOptions.showSourceLabel}
            showPurchasedLabel={labelOptions.showPurchasedLabel}
            isSuggestion={isSuggestion}
            isClaimed={isClaimed}
            isPurchased={isPurchased}
          />
        </List.Description>
      </List.Content>
    </List.Item>
  );
};

export default GiftNext;
