import React from 'react';
import PropTypes from 'prop-types';
import { Label, Icon } from 'semantic-ui-react';

import strings from '../../strings';

const labelColors = {
  source: 'blue',
  claimed: 'violet',
  purchased: 'teal'
};

const LabelsRow = ({
  condensed = false,
  showSourceLabel = true,
  showClaimedLabel = true,
  showPurchasedLabel = true,
  showLink = true, // TODO enable
  isSuggestion,
  isPurchased,
  isClaimed
}) => {
  let giftSourceLabel;
  if (showSourceLabel) {
    if (isSuggestion) {
      giftSourceLabel = condensed ? (
        <Icon circular color="blue" name="lightbulb" />
      ) : (
        <Label color="blue" basic horizontal>
          <Icon name="lightbulb" />
          {strings._labelSuggestion}
        </Label>
      );
    } else {
      giftSourceLabel = condensed ? (
        <Icon circular inverted color="blue" name="pencil" />
      ) : (
        <Label color="blue" horizontal>
          <Icon name="pencil" />
          {strings._labelRequested}
        </Label>
      );
    }
  }

  let claimedLabel;
  if (showClaimedLabel) {
    if (isClaimed) {
      claimedLabel = condensed ? (
        <Icon circular inverted color="violet" name="grab" />
      ) : (
        <Label color="violet" horizontal>
          <Icon name="grab" />
          {strings._labelClaimed}
        </Label>
      );
    } else {
      claimedLabel = condensed ? (
        <Icon circular color="violet" name="grab" />
      ) : (
        <Label color="violet" horizontal basic>
          <Icon name="grab" />
          {strings._labelUnclaimed}
        </Label>
      );
    }
  }

  let purchasedLabel;
  if (showPurchasedLabel) {
    if (isPurchased) {
      purchasedLabel = condensed ? (
        <Icon circular inverted color="teal" name="shopping cart" />
      ) : (
        <Label color="teal" horizontal>
          <Icon name="shopping cart" />
          {strings._labelPurchased}
        </Label>
      );
    } else {
      purchasedLabel = condensed ? (
        <Icon circular color="teal" name="shopping cart" />
      ) : (
        <Label color="teal" horizontal basic>
          <Icon name="shopping cart" />
          {strings._labelNotPurchased}
        </Label>
      );
    }
  }

  // TODO add a link tag
  return (
    <>
      {giftSourceLabel}
      {claimedLabel}
      {purchasedLabel}
    </>
  );
};

LabelsRow.propTypes = {
  showSourceLabel: PropTypes.bool,
  showClaimedLabel: PropTypes.bool,
  showPurchasedLabel: PropTypes.bool,
  isSuggestion: PropTypes.bool,
  isClaimed: PropTypes.bool,
  isPurchased: PropTypes.bool
};

export default LabelsRow;
