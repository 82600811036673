import React from 'react';
import './styles.css';
import loadingIcon from './loading.svg';

const Loading = ({ message = 'Loading' }) => {
  return (
    <div className="loadingComponent">
      {message}
      <img src={loadingIcon} className="App-logo" alt="logo" />
    </div>
  );
};

export default Loading;
