import React, { useEffect, useState } from 'react';
import { firestoreConnect, useFirestore } from 'react-redux-firebase';
import { Button, Grid, Header } from 'semantic-ui-react';
import { withHandlers, compose } from 'react-recompose';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';

import { GIFTS_COLLECTION_NAME, USERS_COLLECTION_NAME } from '../constants';
import Loading from '../global/Loading';

const divStyles = {
  display: 'flex',
  flexDirection: 'column',
  width: '80%',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '1vh auto'
};

const useAddUserToDB = ({ auth, users, firestoreStatus }) => {
  const firestore = useFirestore();
  const [currentlyStoringUser, setCurrentlyStoringUser] = useState(false);
  const [display, setDisplay] = useState('loading');

  useEffect(() => {
    const addToDB = async () => {
      const { uid } = auth;
      const d = await firestore.collection(USERS_COLLECTION_NAME).doc(uid).set({
        displayName: auth.displayName,
        email: auth.email,
        uid: auth.uid,
        photoURL: auth.photoURL,
        lastLoginAt: auth.lastLoginAt,
        createdAt: auth.createdAt
      });

      setCurrentlyStoringUser(false);
    };

    if (
      firestoreStatus.requested[USERS_COLLECTION_NAME] &&
      !firestoreStatus.requesting[USERS_COLLECTION_NAME] &&
      users
    ) {
      if (auth.isEmpty) {
        setDisplay('buttons');
      } else {
        if (!users[auth.uid] && !currentlyStoringUser) {
          setCurrentlyStoringUser(true);
          addToDB();
        } else if (users[auth.uid]) {
          setDisplay('redirect');
        } else {
          console.log('Something went wrong, this should never be hit', {
            auth,
            users
          });
          // debugger;
        }
      }
    }
  }, [auth.isLoaded, users]);

  return {
    display
  };
};

const LoginComponent = ({
  googleLogin,
  facebookLogin,
  auth,
  users,
  firestoreStatus
}) => {
  const { display } = useAddUserToDB({
    auth,
    users,
    firestoreStatus
  });

  if (display === 'loading') {
    return <Loading message="Login Loading" />;
  } else if (display === 'redirect') {
    return <Redirect to="/" />;
  }

  return (
    <div className="login-scene" style={divStyles}>
      <Header as="h3" textAlign="center">
        You must Log in to continue
      </Header>
      <Button
        onClick={googleLogin}
        content="Login with Google"
        icon="google"
        size="large"
        labelPosition="left"
      />
      <Button
        onClick={facebookLogin}
        content="Login with Facebook"
        icon="facebook"
        size="large"
        labelPosition="left"
      />
    </div>
  );
};
export default compose(
  firestoreConnect([
    // { collection: GIFTS_COLLECTION_NAME },
    { collection: USERS_COLLECTION_NAME }
  ]),
  connect((props) => {
    const {
      firebase: { auth },
      firestore: { data, status }
    } = props;

    return {
      auth,
      users: data[USERS_COLLECTION_NAME],
      firestoreStatus: status
    };
  }),
  withHandlers({
    googleLogin:
      ({ firebase }) =>
      (event) => {
        return firebase
          .login({ provider: 'google', type: 'redirect' })
          .catch((err) => console.error(err.message));
      },
    facebookLogin:
      ({ firebase }) =>
      (event) => {
        firebase
          .login({ provider: 'facebook', type: 'redirect' })
          .catch((err) => console.error(err.message));
      }
  })
)(LoginComponent);
