import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import firebase from 'firebase/app';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { createFirestoreInstance } from 'redux-firestore';
import { BrowserRouter as Router } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isLoaded } from 'react-redux-firebase';
import LogRocket from 'logrocket';

import 'firebase/firestore'; // <- needed if using firestore
import 'semantic-ui-css/semantic.min.css';
import 'react-semantic-toasts/styles/react-semantic-alert.css';

import * as serviceWorker from './serviceWorker';
import './index.css';
import App from './App';
import Loading from './global/Loading';
import configureStore from './store';
import initFirebase from './initFirebase';
import { reduxFirebase as rfConfig } from './config';

const store = configureStore();
initFirebase();

LogRocket.init('d8izm4/xmas');

function AuthIsLoaded({ children }) {
  const auth = useSelector((state) => state.firebase.auth);
  if (!isLoaded(auth)) return <Loading message="App Loading" />;

  LogRocket.identify(auth.uid, {
    name: auth.displayName,
    email: auth.email,

    // Add your own custom user variables here, ie:
    firebaseAuth: auth
  });
  return children;
}

ReactDOM.render(
  <Provider store={store}>
    <ReactReduxFirebaseProvider
      firebase={firebase}
      config={rfConfig}
      dispatch={store.dispatch}
      createFirestoreInstance={createFirestoreInstance}
    >
      <AuthIsLoaded>
        <Router>
          <App />
        </Router>
      </AuthIsLoaded>
    </ReactReduxFirebaseProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
