import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import { firestoreConnect } from 'react-redux-firebase';
import _orderBy from 'lodash/orderBy';
import { Header, Segment, Divider } from 'semantic-ui-react';

import { GIFTS_COLLECTION_NAME, USERS_COLLECTION_NAME } from '../constants';

import RecipientWithGifts from '../global/RecipientWithGifts';

const sortUserGifts = (gifts) => {
  return _orderBy(
    gifts,
    (i) => [!!i.isClaimed, !!i.isSuggestion, !!i.isPurchased],
    ['asc', 'asc', 'asc']
  );
};

const myRequestsLabelsOptions = {
  showClaimedLabel: false,
  showSourceLabel: false,
  showPurchasedLabel: false
};

const myClaimsLabelsOptions = {
  showSourceLabel: false
};

const mySuggestionsLabelsOptions = {
  showSourceLabel: false
};

const Me = ({ gifts = [], auth, users = [] }) => {
  const myGifts = _orderBy(
    gifts,
    ['isClaimed', 'createdAt', 'uid'],
    ['asc', 'desc']
  ).filter((currGift) => {
    return !currGift.isSuggestion && currGift.authorId === auth.uid;
  });

  return (
    <div className="me">
      <Segment>
        <Header as="h1">My Requested Gifts ({myGifts.length})</Header>
        You will NOT see if your items have been claimed or purchased
        <Divider />
        <RecipientWithGifts
          user={users[auth.uid]}
          showHeader={false}
          userIsRecipient={true}
          gifts={myGifts}
          showDivider={false}
          showUserUidInList={false}
          labelOptions={myRequestsLabelsOptions}
        />
      </Segment>
      <Segment>
        <Header as="h1">My Claimed Gifts </Header>
        <Divider />
        {/* TODO should use memo or something */}
        {Object.keys(users).map((currUserId, index) => {
          const userGifts = sortUserGifts(
            gifts.filter((currGift) => {
              return (
                currGift.claimerId === auth.uid &&
                currGift.recipientId === currUserId
              );
            })
          );

          if (auth.uid === currUserId || userGifts.length === 0) {
            return null;
          }

          const showDivider = Object.keys(users).length - 1 !== index;

          return (
            <RecipientWithGifts
              key={currUserId}
              userIsRecipient={false}
              user={users[currUserId]}
              showDivider={showDivider}
              gifts={userGifts}
              showUserUidInList={false}
              labelOptions={myClaimsLabelsOptions}
            />
          );
        })}
      </Segment>
      <Segment>
        <Header as="h1">My Suggestions</Header>
        <Divider />
        {/* TODO should use memo or something */}
        {Object.keys(users).map((currUserId, index) => {
          const userGifts = sortUserGifts(
            gifts.filter((currGift) => {
              return (
                currGift.isSuggestion &&
                currGift.recipientId === currUserId &&
                currGift.authorId === auth.uid
              );
            })
          );

          if (auth.uid === currUserId || userGifts.length === 0) {
            return null;
          }

          const showDivider = Object.keys(users).length - 1 !== index;

          return (
            <RecipientWithGifts
              key={currUserId}
              userIsRecipient={false}
              user={users[currUserId]}
              showDivider={showDivider}
              gifts={userGifts}
              showUserUidInList={false}
              labelOptions={mySuggestionsLabelsOptions}
            />
          );
        })}
      </Segment>
    </div>
  );
};

export default compose(
  // TODO should migrate await from firestoreConnect
  firestoreConnect([
    { collection: GIFTS_COLLECTION_NAME },
    { collection: USERS_COLLECTION_NAME }
  ]),
  connect(({ firebase, firestore }) => {
    return {
      gifts: firestore.ordered[GIFTS_COLLECTION_NAME],
      auth: firebase.auth,
      users: firestore.data[USERS_COLLECTION_NAME]
    };
  })
)(Me);
