import React from 'react';
import { Menu, Icon } from 'semantic-ui-react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useFirebase } from 'react-redux-firebase';

const Nav = () => {
  const history = useHistory();
  const location = useLocation();
  const firebase = useFirebase();
  const auth = useSelector((state) => state.firebase.auth);
  const showUidInNav = useSelector((state) => state.debug.showUidInNav);

  const onNavClick = (e, { name }) => {
    if (name === 'logout') {
      firebase.logout();
      history.push('/login');
    } else {
      history.push('/' + name);
    }
  };

  const activeItem = location.pathname.split('/')[1];

  if (auth.isEmpty) return null;

  return (
    <Menu
      icon="labeled"
      fluid
      widths={4}
      inverted
      fixed="top" /*  attached="top" */
    >
      <Menu.Item
        active={activeItem === 'me' || activeItem === ''}
        onClick={onNavClick}
        name="me"
      >
        <Icon name="user" />
        My List {showUidInNav ? `(${auth.uid})` : ''}
      </Menu.Item>
      <Menu.Item
        active={activeItem === 'family'}
        onClick={onNavClick}
        name="family"
      >
        <Icon name="users" />
        Family
      </Menu.Item>
      <Menu.Item active={activeItem === 'add'} onClick={onNavClick} name="add">
        <Icon name="add" />
        Add
      </Menu.Item>
      <Menu.Item name="logout" onClick={onNavClick}>
        <Icon name="sign out" />
        Logout
      </Menu.Item>
    </Menu>
  );
};

export default Nav;
