import React from 'react';
import { isLoaded, isEmpty } from 'react-redux-firebase';
import { Redirect, Switch, Route } from 'react-router-dom';
import { Container, Segment } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { SemanticToastContainer } from 'react-semantic-toasts';

import Nav from './global/Nav';
import Home from './scenes/Me';
import Family from './scenes/Family';
import Add from './scenes/Add';
import Details from './scenes/Details';
import Login from './scenes/Login';
import Admin from './scenes/Admin';

const containerStyle = {
  marginTop: '80px'
};

const AppComponent = () => {
  return (
    <>
      <Nav />
      <Container style={containerStyle}>
        <Switch>
          <PrivateRoute path="/" strict exact>
            <Home />
          </PrivateRoute>
          <PrivateRoute path="/me">
            <Home />
          </PrivateRoute>
          <PrivateRoute path="/family">
            <Family />
          </PrivateRoute>
          <PrivateRoute path="/add/:id?">
            <Add />
          </PrivateRoute>
          <PrivateRoute path="/details/:id">
            <Details />
          </PrivateRoute>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/admin">
            <Admin />
          </Route>
          <Route>
            <Login />
          </Route>
        </Switch>
      </Container>
      <SemanticToastContainer position="bottom-right" />
    </>
  );
};

function PrivateRoute({ children, ...rest }) {
  const auth = useSelector((state) => state.firebase.auth);
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoaded(auth) && !isEmpty(auth) ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

export default AppComponent;
