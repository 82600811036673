import LocalizedStrings from 'react-localization';

let strings = new LocalizedStrings({
  // [scene][Identifier]
  en: {
    _labelClaimed: 'Claimed',
    _labelUnclaimed: 'Unclaimed',
    _labelPurchased: 'Purchased',
    _labelNotPurchased: 'Not Purchased',
    _labelRequested: 'Requested',
    _labelSuggestion: 'Suggestion',
    _addFormHeader: 'Add Gift or Suggestion',
    _addFormIsSuggestionLabel: 'Who is this gift for?',
    _addFormSubmitButtonText: '{0} Gift',
    detailsAlreadyClaimedButton: 'Already Claimed!',
    detailsYouAlreadyClaimedThisButton: 'You Claimed This!',
    detailsNotYetPurchasedThisButton: 'Mark as Purchased',
    detailsYouAlreadyPurchasedThisButton: 'Purchased',
    detailsNotYetClaimedButton: 'Mark as Claimed',
    detailsGiftClaimStatus: '{0} {1} this gift',
    detailsEditButton: 'Edit',
    detailsDeleteButton: 'Delete'
  }
});

export default strings;
