import React from 'react';
import { firebaseConnect, firestoreConnect } from 'react-redux-firebase';
import { Header } from 'semantic-ui-react';
import { withHandlers, compose } from 'react-recompose';
import { connect } from 'react-redux';

import { GIFTS_COLLECTION_NAME } from '../constants';

const divStyles = {
  display: 'flex',
  flexDirection: 'column',
  width: '80%',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '1vh auto'
};

const LoginComponent = ({ archieveAll }) => {
  return (
    <div style={divStyles}>
      <Header as="h3" textAlign="center" onClick={archieveAll}>
        archieveAll from 2019
      </Header>
    </div>
  );
};

export default compose(
  // firebaseConnect(),
  firestoreConnect([
    { collection: GIFTS_COLLECTION_NAME },
    { collection: 'users' }
  ]),
  connect(({ firebase, firestore }) => {
    return {
      gifts: firestore.ordered[GIFTS_COLLECTION_NAME],
      firestore
    };
  }),
  // connect(({ firebase: { auth } }) => ({ auth })),
  withHandlers({
    // onSubmitFail: props => (formErrs, dispatch, err) =>
    //   props.showError(formErrs ? 'Form Invalid' : err.message || 'Error'),
    // login: ({ firebase, showError, router }) => event =>
    //   firebase
    //     .login({ email: 'alexdmejias@gmail.com', password: 'wasdwasd' })
    //     .then(d => history.push('./me'))
    //     .catch(err => console.log(err)),
    // logout: ({ firebase}) => creds =>
    //   firebase
    //     .logout(),
    // googleLogin: ({ firebase, showError, router }) => event => {
    //   return firebase
    //     .login({ provider: "google", type: "redirect" })
    //     .then(d => history.push("/me"))
    //     .catch(err => console.error(err.message));
    // },
    // facebookLogin: ({ firebase, showError, router }) => event => {
    //   firebase
    //     .login({ provider: "facebook", type: "redirect" })
    //     .then(d => history.push("/me"))
    //     .catch(err => console.error(err.message));
    // }
    archieveAll:
      ({ gifts, firestore }) =>
      (uid) => {
        // firestore.update({ collection: 'gifts', doc: todo.id }, { claimed: !todo.claimed, claimer: uid})
        const end = new Date('2020-01-01');
        const coll = firestore.collection('gifts').where('createdAt', '<', end);

        firestore.runTransaction(function (transaction) {
          // return transaction.get(sfDocRef).then(function (sfDoc) {
          // if (!sfDoc.exists) {
          //   throw 'Document does not exist!';
          // }
          // var is2019 = sfDoc.data().archieve + 1;
          // if (newPopulation <= 1000000) {
          //   transaction.update(sfDocRef, { population: newPopulation });
          //   return newPopulation;
          // } else {
          //   return Promise.reject('Sorry! Population is too big.');
          // }
          // });
        });
        // console.log("alexalex - #########", gifts);
        // const coll = firestore.collection("gifts");

        // firestore
        //   .collection("gifts")
        //   .where("createdAt", "<", end)
        //   .get()
        //   .then(function(querySnapshot) {
        //     querySnapshot.forEach(function(doc) {
        //       // doc.data() is never undefined for query doc snapshots
        //       console.log(doc.id, " => ", doc.data());
        //     });
        //   })
        //   .catch(function(error) {
        //     console.log("Error getting documents: ", error);
        //   });
        // coll
        //   .where("createdAt", "<", end)
        //   .get()
        //   .then(query =>
        //     query.forEach(doc => console.log("alexalex - ++++++++++", doc))
        //   );
        // console.log(items);
      }
  })
)(LoginComponent);
